import React from 'react';
import { graphql } from 'gatsby';

import * as Core from 'components/core';
import Layout from 'components/departments/DepartmentLayout';

import Quotes, { Props as QuotesProps } from 'components/overheard/Quotes';

interface Props {
  data: QuotesProps & {
    image: Core.ImageAttributes;
  };
}

const DepartmentContent: React.FC<Props> = (props) => {
  const { quotes, image } = props.data;

  return (
    <Layout departmentSlug="overheard" title="Overheard">
      <Core.ContentWrapper>
        <Core.ContentSection id="campus-visitor">
          <Core.DepartmentLabel>Overheard</Core.DepartmentLabel>
          <Core.Flag>Campus Visitor</Core.Flag>
          <Core.PrimaryHeading>
            Making Women’s Empowerment Mainstream
          </Core.PrimaryHeading>

          <p>
            <Core.LeadIn>Condoleeza Rice</Core.LeadIn> believes that empowering
            women is an integral part to improving the world.{' '}
          </p>
          <p>
            So it is only fitting that Johns Hopkins SAIS has created a lecture
            series to honor her: The Condoleeza Rice “Women Who Inspire” series.
            The goal of the series is to highlight women who reshape the world,
            confront global challenges, and who blaze the trails across all
            walks of life.
          </p>
        </Core.ContentSection>

        <Core.FullWidthImage image={image} alt="Bill Gates" />

        <Core.ContentSection>
          <p>
            At the series’ dedication last May, Rice noted that during her time
            as Secretary of State [2005–2009], she found “the countries [that]
            were the most conflict-ridden all had something in common: They all
            devalued women.”{' '}
          </p>
          <p>
            The May 2 dedication also marked the series’ first lecture, given by
            Ana Palacio, Spain’s former Minister of Foreign Affairs (2002–2004).
            An accomplished international lawyer specializing in international
            and European Union law, Palacio has dedicated her career to
            advocating for democracy. She began her lecture by quoting the
            opening line of Rice’s memoir: “Never be the helpless victim of your
            circumstances.”
          </p>
          <p>
            Both Rice and Palacio believe that women’s issues should not be
            compartmentalized but instead be woven into daily affairs. Their
            ultimate goal? To see all women empowered to overcome injustice and
            inequity and emerge as victors.
          </p>
          <Core.Share id="campus-visitor" />
        </Core.ContentSection>

        <Quotes quotes={quotes} />

        <Core.MoreResources>
          To see all SAIS Campus events, visit{' '}
          <a
            href="http://events.sais-jhu.edu/#the-recap"
            target="_blank"
            rel="noopener noreferrer"
          >
            events.sais-jhu.edu/#the-recap
          </a>
        </Core.MoreResources>
      </Core.ContentWrapper>
    </Layout>
  );
};

export const query = graphql`
  query($directory: String!) {
    quotes: allFile(
      filter: { relativePath: { regex: $directory }, name: { eq: "quotes" } }
    ) {
      ...OverheardQuotes
    }
    image: file(
      relativePath: { regex: $directory }
      name: { eq: "condoleeza-rice-visitor" }
    ) {
      ...FullWidthImage
    }
  }
`;

export default DepartmentContent;
